<template>
  <div v-if="mappedNav?.length" :class="['header-nav-container width-100', { 'nav-open': navStore.navOpen }]">
    <slot />
    <section :class="['nav-container', `lvl-${nestLevel}`, { 'main-nav-open': navStore.desktopNavExpanded }]">
      <nav>
        <ul :class="['main-links']" @mouseleave="!isMobileOrTablet ? navStore.setSubnavOpenIndex(null) : {}">
          <li
            v-for="(item, topMenuIdx) in isMobileOrTablet ? mappedNavMobile : mappedNav"
            :key="'topMenu' + topMenuIdx"
            :class="`lvl-${nestLevel}`"
            :aria-haspopup="item.hasDropdownContent"
            :aria-expanded="navStore.subnavOpenIndex === topMenuIdx"
            :tabindex="topMenuIdx"
            @mouseenter="!isMobileOrTablet ? navStore.setSubnavOpenIndex(topMenuIdx) : {}"
            @keydown.enter="!isMobileOrTablet ? navStore.setSubnavOpenIndex(topMenuIdx) : {}"
          >
            <CustomButton
              v-bind="item"
              :is-menu-item="true"
              :class="{
                'is-bold': item.boldItem,
                'has-desc': item.shortDescription,
                'has-badge': item.hasBadge,
              }"
              :aria-attrs="{
                'aria-haspopup': item.hasDropdownContent,
                'aria-expanded': navStore.subnavOpenIndex === topMenuIdx,
              }"
              style-name="tertiary"
              style-color="black"
              class="main-link"
              @click-button="isMobileOrTablet ? navStore.toggleMenu(topMenuIdx) : {}"
            >
              <template v-if="item.hasIcon" #icon>
                <slot name="menuIcon">
                  <Store3Line />
                </slot>
              </template>
              <CloudinaryImage
                v-if="item?.mobile_image"
                :cloudinary-src="item?.mobile_image"
                :options="{ width: 48, height: 48 }"
                :image-classes-override="['menu-item-img', 'tab-mob-only']"
              />
              {{ item?.title }}
              <template v-if="item.hasDropdownContent" #secondaryIcon>
                <div class="dropdown-svg lvl-0">
                  <ArrowRightSLine />
                </div>
              </template>
            </CustomButton>
            <LazyDropdownMenu
              v-if="item.hasDropdownContent"
              :nav-title="item.title"
              :menu="item.link"
              :nest-level="nestLevel + 1"
              :parent-index="topMenuIdx"
              :nav-open="navStore.subnavOpenIndex === topMenuIdx"
              :content-img="item?.contentImg"
              :highlight-section="item?.productHighlightSection"
              :featured-nav="item?.featuredNav"
            />
            <div
              v-if="navStore.subnavOpenIndex === topMenuIdx"
              class="desktop-open-nav-overlay"
              @mouseenter="navStore.setSubnavOpenIndex(null)"
            />
          </li>

          <!-- client-only wrapper seems to fix double load of account/cart buttons -->
          <client-only>
            <Algolia
              v-if="isMobileOrTablet && !isHomepage ? navStore.searchIsOpen : true"
              :class="[{ 'big-flex': navStore.isSearch }]"
              :is-open="navStore.navMode === 'search'"
              render-mode="dropdown"
              :nav-open="navStore.navOpen"
              emit-click="click-button"
              emit-closer="child-closer"
              @click-button="navStore.openSearch"
              @child-closer="navStore.algoliaClose"
            />
            <template #fallback>
              <div class="skeleton skeleton-section" :style="['height: 38px', 'margin-top: 10px']" />
            </template>
          </client-only>

          <CustomButton
            :to="localePath('/account')"
            :is-menu-item="true"
            :icon-only="true"
            style-name="tertiary"
            style-color="black"
            aria-label="Account Page"
            class="desk-only-flex account-link"
            @mouseenter="setShowEmail(true)"
            @mouseleave="setShowEmail(false)"
          >
            <div class="account-icon flex">
              <AccountCircleLine />
            </div>
          </CustomButton>
          <div v-if="customerStore.loggedIn && showAccountEmail" class="account-email">
            {{ customerStore?.customer?.email }}
          </div>
          <CustomButton
            :is-menu-item="true"
            :icon-only="true"
            style-name="tertiary"
            style-color="black"
            aria-label="Show Cart Modal"
            class="desk-only-flex cart-link"
            @click-button="openCartModal()"
          >
            <div class="cart-header-container flex">
              <ShoppingCartLine />
              <div v-if="!!numItemsCart" class="num-items">
                {{ numItemsCart }}
              </div>
            </div>
          </CustomButton>

          <div class="user-menu">
            <li v-for="(userItem, i) in mappedUserNav" :key="userItem?.title + i + 'user-menu'">
              <CustomButton
                v-bind="userItem"
                :is-menu-item="true"
                style-name="tertiary"
                style-color="black"
                class="main-link"
                @click-button="navStore.navClose()"
              >
                <SvgTemplate v-if="userItem?.svg" :svg-name="userItem?.svg" />
                {{ userItem?.title }}
              </CustomButton>
            </li>
            <li v-if="locales?.length > 1">
              <CustomButton
                :is-menu-item="true"
                style-name="tertiary"
                style-color="black"
                class="main-link locale"
                @click-button="
                  {
                    navStore.navClose();
                    navStore.setLocaleModalOpen(!navStore.localeModalOpen);
                  }
                "
              >
                <img
                  v-if="matchedLocale?.flagUrl"
                  :src="matchedLocale?.flagUrl"
                  :height="flagDimensions.height"
                  :width="flagDimensions.width"
                  alt="flag"
                  class="country-img"
                  data-categories="essential"
                />
                <GlobalLine v-else class="global-icon country-img" />
                {{ country }} / {{ lang }}
              </CustomButton>
            </li>
          </div>
        </ul>
      </nav>
    </section>
  </div>
</template>

<script setup lang="ts">
import { useNavStore } from '~/store/nav';
import { useDeviceStore } from '~/store/device';
import { useLocaleStore } from '~/store/locale';
import { useCartStore } from '~/store/cart';
import { useCustomerStore } from '~/store/customer';
import { Link } from '~/types/link-menu';
import { mapMenuItem } from '~/util/contentstack/csHelpers';

const { locale, locales } = useI18n();

const localePath = useLocalePath();
const deviceStore = useDeviceStore();
const navStore = useNavStore();
const route = useRoute();
const customerStore = useCustomerStore();

const nestLevel = 0;
const showAccountEmail = ref(false);

const mappedNav = navStore.headerMainNav.map((item: Link) => {
  // on desktop, we want top level nav ot be clickable
  return mapMenuItem(item);
});
const mappedNavMobile = navStore.headerMainNav.map((item: Link) => {
  // false here removes top level link from expandable buttons
  return mapMenuItem(item, false);
});
const mappedUserNav = navStore.headerMobileUserNav.map((item: Link) => {
  return mapMenuItem(item);
});

const path = computed(() => route.path?.toLowerCase());
const langCode = computed(() => useLocaleStore().langCode);
const isMobileOrTablet = computed(() => deviceStore.isMobileOrTablet);
const isHomepage = computed(() => path.value === '/' + langCode.value || path.value === '/' + langCode.value + '/');
const numItemsCart = computed(() => useCartStore().numItemsCart);
const matchedLocale = computed(() => {
  return (locales?.value as any)?.find((i: any) => i.code === locale.value);
});
const country = computed(() => matchedLocale.value?.country);
const lang = computed(() => matchedLocale.value?.lang);
const flagDimensions = computed(() =>
  isMobileOrTablet.value ? { height: '22px', width: '42px' } : { height: '12px', width: '23px' }
);

// METHODS
// no-hoist method
const openCartModal = () => useCartStore().setShowCartModal(true);
const setShowEmail = (val: boolean) => (showAccountEmail.value = val);
</script>

<style lang="scss" scoped>
.main-links {
  display: flex;
  align-items: center;

  @include local-mixins.tablet_and_mobile {
    flex-direction: column;
    width: 100%;
    li,
    .btn.main-link,
    :deep(.btn.main-link .btn-a-children) {
      width: 100%;
    }
  }
}

.account-email {
  display: flex;
  position: absolute;
  right: 0;
  top: 115px;
  z-index: 5;
  padding: 10px;
  font-size: 14px;
  border: 1px solid $color-neutral-cool-200;
  background: $color-neutral-white;
  border-top: none;
  margin: 0 calc((100vw - 88.75rem) / 2);
}

.account-icon {
  &::after {
    content: ' ';
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: $color-primary-500;
    position: relative;
    top: 0;
    left: -2px;
  }
}

:deep(.menu-item-img) {
  height: 3rem !important;
  width: 3rem !important;
  margin-right: 1rem;
}

.header-nav-container {
  @include local-mixins.tablet_and_mobile {
    // display: none;
    transition: left 0.3s ease;
    position: fixed;
    top: 0;
    // left: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background: $color-neutral-white;
    z-index: map.get(local-vars.$zindex, 'fixed');

    &.nav-open {
      left: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .nav-container {
    overflow: auto;

    @include local-mixins.tablet_and_mobile {
      border-top: 1px solid $color-neutral-cool-200;
      padding: 0.5rem 18px;
      padding-bottom: 4rem;
      flex: 1;
      .menu-item.btn {
        font-weight: normal;
      }
      li.lvl-0 {
        padding-top: 6px;
        padding-bottom: 6px;
        &:not(:last-child) {
          border-bottom: 1px solid $color-neutral-cool-100;
        }
        .menu-item.btn.main-link {
          font-weight: 500;
          border-bottom: none;
        }
      }

      .is-bold.btn {
        font-weight: 500;
      }
    }
  }
}

.user-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 5rem;
  @include local-mixins.desktop {
    display: none;
  }
  @include local-mixins.tablet_and_mobile {
    .btn.menu-item.main-link {
      font-size: 0.875rem;
    }
  }
}

.btn.menu-item {
  align-items: center;
  border-radius: initial;
  display: flex;
  font-family: inherit;
  justify-content: flex-start;
  padding: local-functions.rem-calc(10 10 10 18);
  height: 3rem;
  width: 100%;
  font-size: local-functions.rem-calc(18);
  text-transform: none;
  white-space: nowrap;

  @include local-mixins.desktop {
    height: local-functions.rem-calc(62);
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:hover,
  &:hover:not(:focus),
  &:focus {
    border: none;
  }

  &.main-link {
    font-size: 1rem;
    padding: 10px 15px;
    text-transform: none;
    &.locale {
      text-transform: uppercase;
    }
    @include local-mixins.tablet_and_mobile {
      padding-right: 0;
    }
    @include local-mixins.desktop {
      width: auto;
    }
  }

  .btn-a-children {
    width: 100%;
  }

  .dropdown-svg {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  @include local-mixins.tablet_and_mobile {
    padding: 8px;
    svg {
      margin-right: 0;
      margin-left: -8px;
    }
  }

  @include local-mixins.desktop {
    display: flex;
    font-size: 1rem;
    width: initial;
    padding: 10px 9px;
    position: relative;

    .dropdown-svg {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $color-primary-600;
      transition: width 0.3s ease;
    }
    &:hover,
    &:hover:not(:focus),
    &:focus {
      &:not(.icon-only) {
        &::before {
          width: 100%;
        }
      }
    }

    &:hover,
    &:focus {
      background-color: $color-neutral-white;
      color: $color-primary-600;
      fill: $color-primary-600;
      svg {
        fill: $color-primary-600;
      }
    }
  }
}
.country-img {
  height: 22px;
  width: auto;
  margin-right: 5px;
  @include local-mixins.desktop {
    height: 12px;
  }
}
</style>
